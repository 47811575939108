* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  background-color: aliceblue;
  font-family: 'Roboto', sans-serif;
}

.title {
  font-size: 2.1rem;
  font-weight: 500;
  width: fit-content;
  -webkit-text-stroke: midnightblue;
  -webkit-text-stroke-width: 1px
}

.header {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding: 1rem 2rem;
  gap: 1.2rem;
  color: white;
  background-image: linear-gradient(90deg, #04004e 0%, #090979 35%, #00748b 100%);
}

.logo-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 10px;
}

#hrnet-logo {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 20px;
  width: 60px;
  height: auto;
  padding: 10px;
  background-color: aliceblue;
  border-radius: 50%;
}

#header-illustration {
  width: 130px;
}

.link-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.6rem;
  width: 570px;
  height: 100%;
}

.link-title {
  width: 100%;
  font-size: 1.8rem;
  text-align: center;
  overflow: hidden;
}

.link-title:before,
.link-title:after {
  background-color: aliceblue;
  content: "";
  display: inline-block;
  height: 2px;
  position: relative;
  vertical-align: middle;
  width: 50%;
}

.link-title:before {
  right: 0.5em;
  margin-left: -50%;
}

.link-title:after {
  left: 0.5em;
  margin-right: -50%;
}

.header-link {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 1.2rem;
  color: white;
  text-decoration: none;
  transition: 0.3s;
}

.header-link:hover {
  font-weight: 500;
  transform: scale(1.1);
}

label {
  display: block;
  margin-top: 1rem;
  margin-bottom: 10px;
}

.address {
  margin-top: 15px;
  padding: 20px;
  border-radius: 5px;
  border-color: aliceblue;
}

.create-employee-page { 
  position: relative;
}

.form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: aliceblue;
  background-image: url("../../public/assets/form-container-illustration.svg");
  background-repeat: no-repeat;
  background-size: cover;
}

form {
  min-width: 500px;
  padding: 15px 30px 30px;
  margin: 3rem 1.5rem;
  background-color: midnightblue;
  border-radius: 5px;
  font-weight: 500;
  color: white;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

input:not(.save-btn) {
  width: 100%;
  height: 40px;
  padding: 0 10px;
  border-radius: 5px;
  color: midnightblue;
  font-size: 1rem;
  font-weight: 700;
}

.modal-container {
  position: fixed;
  top: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.384);
  backdrop-filter: blur(1px);
}

.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%; 
  transform: translate(-50%, -50%); 
  background-color: midnightblue;
  color: white;
  padding: 2rem 5rem;
  border-radius: 10px;
}

.modal-message {
  position: relative;
  color: white;
  font-size: 1.1rem;
  font-weight: 500;
}

.modal-close-btn {
  border-radius: 50%;
  background-color: midnightblue;
  color: white;
  position: absolute;
  top: -43px;
  right: -87px;
  height: 1.4em;
  cursor: pointer;
}

.save-btn-container {
  padding-top: 30px;
}

.save-btn {
  margin: 0 auto;
  padding: 15px 45px;
  text-align: center;
  font-weight: bold;
  font-size: 1rem;
  transition: 0.5s;
  outline: auto;
  outline-width: 1px;
  cursor: pointer;
  background-size: 200% auto;
  color: aliceblue;            
  box-shadow: 0 0 20px #eee;
  border-radius: 10px;
  display: block;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
  background-image: linear-gradient(to right, #1A2980 0%, #4f56b9  51%, #1A2980  100%)
}

.save-btn:hover {
  background-position: right center; 
  color: #fff;
  text-decoration: none;
  outline: auto;
  transform: scale(1.1);
}

.no-scroll { 
  overflow: hidden;
}

.hidden {
  display: none;
}

.react-select input {
  height: 25px;
}

.employee-table-container {
  width: 80%;
  margin: 0 auto;
}

.employee-table-page {
  height: 100vh;
  background-image: url("../../public/assets/form-container-illustration.svg");
  background-repeat: no-repeat;
  background-size: cover;
}

